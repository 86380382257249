<template>
  <div class="choose-bg">
    <div class="scroll" id="scroll">
      <div style="height:0.72rem;flex-shrink: 0;"></div>
      <div class="hot">热门平台</div>

      <div class="list" v-if="keywords.length == 0">
        <a class="item row" v-for="social in socials" :key="social.id" @click="chooseItem(social)">
          <img class="item-logo" :src="social.logo" alt="">
          <div class="item-name">{{ social.name }}</div>
        </a>
      </div>
      <div class="list" v-if="keywords.length > 0">
        <a class="item row" v-for="social in searchs" :key="social.id" @click="chooseItem(social)">
          <img class="item-logo" :src="social.logo" alt="">
          <div class="item-name" v-html="lightFn(social.name)"></div>
        </a>
        <a class="item row" @click="chooseCustom">
          <img class="item-logo" src="../assets/images/custom_link.png" alt="">
          <div class="item-name" v-html="lightFn('使用“' + keywords + '”做平台名称')">
          </div>
        </a>
      </div>
      <div class="footer" v-if="showFooter"></div>
    </div>

    <!-- <div style="flex-shrink:0;height:500px;background-color:red;width:100px"></div>
    <div style="flex-shrink:0;height:500px;background-color:blue;width:100px"></div>
    <div style="flex-shrink:0;height:500px;background-color:yellow;width:100px"></div> -->
    <div class="row searchbar">
      <div class="search">
        <span class="icon-search" style="color:#74747AFF"></span>
        <input ref="inputText" class="search-input" type="text" placeholder="请输入平台名称" v-model.trim="keywords">
      </div>
      <a class="cancel" @click="cancel">取消</a>
    </div>
  </div>
</template>

<script>
import { get, post } from '../config/http';
import { isiOS } from '../config/utils'
import api from '../config/api';
import MonitorKeyboard from '../config/monitor_keyboard.js'
export default {
  props: {
    msg: String
  },
  data() {
    return {
      socials: [],
      searchs: [],
      keywords: '',
      more: this.$route.query.more,
      showFooter: false
    }
  },
  watch: {
    keywords(k) {
      var array = []
      this.socials.map((item) => {
        if (item.name.indexOf(k) != -1) {
          array.push(item)
        }
      })
      this.searchs = array;
    }
  },
  mounted() {
    this.getKeyboardState();
    var _this = this;
    get(api.socials, { all: true })
      .then((data) => {
        _this.socials = data.data
      })
    const scrollview = document.getElementById('scroll')
    scrollview.addEventListener('scroll', () => {
      _this.$refs.inputText.blur()
    }, true)
  },
  beforeDestroy() {
    this.monitorKeyboard.onEnd()
  },
  methods: {
    getKeyboardState() {
      this.monitorKeyboard = new MonitorKeyboard();
      this.monitorKeyboard.onStart();

      // 监听虚拟键盘弹出事件
      var _this = this;
      this.monitorKeyboard.onShow(() => {
        if (isiOS()) {
          _this.showFooter = true
        }

      })

      //监听键盘收起的事件
      this.monitorKeyboard.onHidden(() => {
        _this.showFooter = false
      })
    },
    lightFn(name) {
      const res = new RegExp(this.keywords, 'ig')
      return name.replace(res, (item) => {
        return `<span style="color:#FF9900FF">${item}</span>`
      })
    },
    cancel() {
      this.$router.back()
    },
    chooseItem(social) {
      localStorage.setItem('lastSocial',JSON.stringify(social))
      if (this.$route.query.update) {
        global.social = social;
        this.$router.back()
        return
      }
      
      if (global.addLink) {
        this.$router.push({ path: '/platform/link', query: {  more: this.more } }).catch(err=>{})
      }
      else {
        this.$router.push({ path: '/platform/link', query: {  more: this.more } }).catch(err=>{})
      }

    },
    chooseCustom() {
      var social = {
        id: "",
        name: this.keywords,
        logo: "",
        types: ["LINK", "QR"],
        description_example: "",
        url_example: ""
      }
      if (this.$route.query.update) {
        global.social = social;
        this.$router.back()
        return
      }
      localStorage.setItem('lastSocial',JSON.stringify(social))
      if (global.addLink) {
        this.$router.push({ path: '/platform/link', query: { social: JSON.stringify(social) } }).catch(err=>{})
      }
      else {
        this.$router.push({ path: '/platform/link', query: { social: JSON.stringify(social) } }).catch(err=>{})
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang='scss' scoped>
div,
span {
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.scroll {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  background-color: #131314;
}

.footer {
  height: 400px;
  flex-shrink: 0;
}

.choose-bg {
  // height: 100%;
  display: flex;
  flex-direction: column;
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #131314;
}

.searchbar {
  height: 0.72rem;
  background-image: linear-gradient(1deg, rgba(19, 19, 20, 0.00) 0%, #131314 22%);

  // background: linear-gradient(360deg, rgba(19, 19, 20, 0) 0%, #131314 100%);
  padding: 0 0.16rem;
  position: fixed;
  left: 0;
  top: 0;
  right: 0;

  .search {
    width: 2.87rem;
    height: 0.4rem;
    background: #2C2C2E;
    border-radius: 0.24rem;
    align-items: center;
    display: flex;
    flex-direction: row;
    padding-left: 0.10rem;
    box-sizing: border-box;

    .search-input {
      background-color: transparent;
      flex: 1;
      color: #fff;
      caret-color: #FF9900FF;
    }

  }

  .cancel {
    color: #FFF700FF;
    font-size: 0.14rem;
    margin-left: 0.22rem;
  }
}

.hot {
  margin-top: 0.0rem;
  color: #74747AFF;
  font-size: 0.12rem;
  font-weight: bold;
  margin-left: 0.28rem;
  text-align: left;
}

.list {
  margin-top: 0.1rem;

  .item {
    width: 3.43rem;
    height: 0.48rem;
    border-radius: 0.12rem;
    margin-left: 0.16rem;
    padding-left: 0.12rem;
    box-sizing: border-box;

    .item-logo {
      width: 0.32rem;
      height: 0.32rem;
      border-radius: 0.04rem;
    }

    .item-name {
      font-size: 0.14rem;
      margin-left: 0.12rem;
      color: #fff;
    }
  }

  .item:active {
    background: #2C2C2E;
  }

  // .item:hover {
  //   background: #2C2C2E;
  // }
}
</style>